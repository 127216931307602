import React, { useState, useEffect, useContext } from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import Icon from 'browser/components/Icon';
import heartIcon from 'browser/assets/icons/heart.svg';
import fullHeartIcon from 'browser/assets/icons/full-heart.svg';
import pdfIcon from 'browser/assets/icons/pdf.svg';
import Button from 'browser/components/Button';
import { FavouritesContext } from 'contexts/FavouritesContext';
import { NavigationContext } from 'contexts/NavigationContext';
import LoadedImage from 'browser/components/LoadedImage';
import { useHistory } from 'react-router-dom';
import { getPriceView } from 'browser/utils/getPriceView';
import emitMatomoEvent, { eventsNames } from 'utils/emitMatomoEvent';

const getMatomoEventName = (isPromotionFlatCard, eventName) => {
  switch (eventName) {
    case 'click_mieszkanie_karta':
      if (isPromotionFlatCard)
        return eventsNames.click_mieszkanie_promocja_karta;
      return eventsNames.click_mieszkanie_karta;
    case 'click_pietro_zmiana':
      if (isPromotionFlatCard)
        return eventsNames.click_pietro_zmiana_karta_promocja;
      return eventsNames.click_pietro_zmiana_karta;
    case 'click_spacer_3d':
      if (isPromotionFlatCard)
        return eventsNames.click_spacer_3d_karta_promocja;
      return eventsNames.click_spacer_3d_karta;
    case 'click_ulubione_karta_dodaj':
      if (isPromotionFlatCard)
        return eventsNames.click_ulubione_karta_promocja_dodaj;
      return eventsNames.click_ulubione_karta_dodaj;
    case 'click_ulubione_karta_usun':
      if (isPromotionFlatCard)
        return eventsNames.click_ulubione_karta_promocja_usun;
      return eventsNames.click_ulubione_karta_usun;
    default:
      return '';
  }
};

const Wrapper = styled.article`
  display: flex;
  position: relative;
  flex-direction: column;
  border: 1px solid #f3f0ee;
  transition: 0.3s;
  &:hover {
    border-color: #e60060;
  }
`;

const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-decoration: none;
  color: #272727;
  cursor: pointer;
  height: 100%;
`;

const Main = styled.div`
  padding: 25px 15px;
  background: #f3f0ee;
  @media (min-width: 1200px) {
    padding: 30px;
  }
`;

const CardHeading = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 15px;
  border-bottom: 1px solid #e1dad5;
`;

const Heading = styled.h5`
  font-size: 1.8rem;
  span {
    font-weight: 700;
    &:after {
      content: '';
      display: inline-block;
      margin: 0 10px;
      width: 1px;
      height: 15px;
      margin-bottom: -1px;
      background: #5c5550;
    }
  }
`;

const FavBtn = styled.button`
  position: absolute;
  top: 30px;
  right: 15px;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  cursor: pointer;
  @media (min-width: 1200px) {
    top: 35px;
    right: 35px;
  }
  &:hover {
    span {
      opacity: 1;
      visibility: visible;
      transform: scale(1) translate(0, -50%);
    }
  }
  span {
    display: block;
    padding: 2px 5px;
    white-space: nowrap;
    position: absolute;
    pointer-events: none;
    top: 50%;
    right: 25px;
    z-index: 1;
    transition: 0.3s;
    background: #fff;
    border: 1px solid #e1dad5;
    opacity: 0;
    transform: scale(0.7) translate(20px, -50%);
    visibility: hidden;
    border-radius: 4px;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.1);
  }
  // remove if there is mobile 3D browser
  @media (max-width: 991px) {
    display: none !important;
  }
`;

const ImageWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
  width: 100%;
  height: 260px;
  overflow: hidden;
  img {
    height: auto;
    width: 100%;
    max-height: 290px;
  }
  & > div:first-child {
    height: 100%;
  }
  @media (min-width: 768px) {
    height: 350px;
    img {
      height: auto;
      width: auto;
      max-height: 100%;
    }
  }
  @media (min-width: 992px) {
    height: 300px;
  }
  ${({ withXXXLStyles }) =>
    withXXXLStyles &&
    css`
      @media (min-width: 1600px) {
        height: 350px;
      }
    `}
`;

const CardFooterText = styled.p`
  color: #e60060;
  font-size: 2rem;
`;

const CardFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  padding: 10px 0;
  border-top: 1px solid #f2ece7;
  svg {
    fill: #e60060;
  }
  @media (max-width: 991px) {
    display: none !important;
  }
`;

const AskButton = styled.div`
  display: none;
  @media (max-width: 991px) {
    display: block;
    width: 100%;
    margin-top: 15px;
    & > button {
      border-width: 1px;
      width: 100%;
    }
  }
`;

const PdfButtonLink = styled.a`
  display: none;

  @media (max-width: 991px) {
    display: block;
    width: 100%;
    margin-top: 15px;
    & > button {
      border-width: 1px;
      width: 100%;
    }
    img {
      margin-left: 15px;
    }
  }
`;

const CardContent = styled.div`
  background: ${({ theme }) => theme.colors.white};
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  padding: 10px 15px;
  margin-top: auto;
  @media (min-width: 1200px) {
    padding: 20px 30px;
  }
`;

const Label = styled.div`
  display: flex;
  align-items: center;
  font-size: 1.5rem;
  color: #5c5550;
  padding: 10px 0 5px;
  font-weight: 500;
  text-transform: uppercase;
`;

const StyledLabel = styled(Label)`
  padding: 0;
  align-items: flex-start;
  span {
    margin-top: 6px;
  }
`;

const Highlight = styled.span`
  margin-left: 10px;
  color: #e60060;
`;

const FloorsWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-left: 5px;
`;

const FloorBtn = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  cursor: pointer;
  font-weight: 500;
  padding: 0;
  font-size: 1.5rem;
  transition: 0.3s;
  background: ${({ active }) => (active ? '#F3F0EE' : 'transparent')};
  color: ${({ active }) => (active ? '#e60060' : '#272727')};
  cursor: ${({ active }) => (active ? 'default' : 'pointer')};
  &:hover {
    background: ${({ active }) => (active ? '#F3F0EE' : '#F7F7F7')};
  }
  ${({ disabled }) =>
    disabled &&
    css`
      display: none;
      /* opacity: 0.4;
      cursor: not-allowed;
      &:hover {
        background: transparent;
      } */
    `}
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const ButtonWrapper = styled.div`
  margin-bottom: 5px;
  button {
    height: 40px;
    padding: 0 15px;
    @media (max-width: 350px) {
      font-size: 1.3rem;
      padding: 0 10px;
    }
  }
`;

const ViewsWrapper = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
`;

const PlanButton = styled.button`
  position: relative;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #e1dad5;
  background: #f3f0ee;
  transition: 0.3s;
  width: 40px;
  height: 40px;
  &:not(:last-child) {
    border-bottom: none;
  }
  div {
    color: #cec3bb;
    font-weight: 700;
    font-size: 1.6rem;
  }
  svg {
    fill: #cec3bb;
    transition: 0.3s;
  }
  ${({ active }) =>
    active &&
    css`
      background: #fff;
      svg {
        fill: #e60060;
      }
    `}
  &:hover {
    span {
      opacity: 1;
      visibility: visible;
      transform: scale(1) translate(0, -50%);
    }
  }
  span {
    display: block;
    padding: 2px 5px;
    white-space: nowrap;
    pointer-events: none;
    position: absolute;
    top: 50%;
    right: 45px;
    z-index: 1;
    transition: 0.3s;
    background: #fff;
    border: 1px solid #e1dad5;
    opacity: 0;
    transform: scale(0.7) translate(20px, -50%);
    visibility: hidden;
    border-radius: 4px;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.1);
  }
`;

const PromoLabel = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  padding: 5px 8px;
  background: #e60060;
  color: #fff;
  max-width: max-content;
  font-weight: 700;
  font-size: 1.4rem;
  border-radius: 4px;
  min-width: 80px;
  margin: 4px 0;
`;

const PriceWrapper = styled.div`
  margin-left: 8px;
`;

const PriceLabel = styled.span`
  position: relative;

  :first-child {
    margin-right: 8px;
  }

  ${({ marginLeft }) =>
    marginLeft &&
    css`
      margin-left: 8px;
    `}

  ${({ highlighted }) =>
    highlighted &&
    css`
      color: #e60060;
    `}

  ${({ crossedOut }) =>
    crossedOut &&
    css`
      font-size: 13px;

      &:after {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 1px;
        background-color: #888888;
      }
    `}
`;

const FlatCard = ({
  flatData,
  similarFlats,
  showTour3D,
  toggleForm,
  isPromotionFlatCard,
}) => {
  const history = useHistory();
  const [viewType, setViewType] = useState('flat');
  const [visibleFlat, setVisibleFlat] = useState(flatData);
  const { checkIsFavourite, handleFavOption } = useContext(FavouritesContext);
  const {
    isPromotionDrawerVisible,
    closePromotionDrawer,
    savedBrowserHistory,
    saveBrowserHistory,
  } = useContext(NavigationContext);

  useEffect(() => {
    if (!isPromotionFlatCard && !savedBrowserHistory) {
      saveBrowserHistory(history);
    }
  }, [history]);

  const {
    flatID,
    name,
    PDF,
    PNG,
    PNGFloor,
    rooms,
    floor,
    area,
    price,
    possibleToBook,
    pricePromotion,
    isPriceVisible,
    isPromotion,
    promotionViewType,
    tour3D,
    investment,
    status,
  } = visibleFlat;

  const priceView = getPriceView({
    isPromotion,
    promotionViewType,
    regularPriceVisible: isPriceVisible,
  });

  const includedFloors = visibleFlat.similarFlats ? similarFlats : [floor];

  const checkIsFloorDisabled = el => {
    return (
      name !== el &&
      (!similarFlats || !similarFlats.map(layout => layout.name).includes(name))
    );
  };

  const handleFloorClick = clickedFloor => {
    if (!checkIsFloorDisabled(clickedFloor)) {
      const newVisibleFlat = similarFlats.find(el => el.name === clickedFloor);
      setVisibleFlat(newVisibleFlat);
    }
  };

  const handleTourButton = () => {
    showTour3D(tour3D, name);
    if (window.dataLayer) {
      window.dataLayer.push({
        event: 'walk',
        mieszkanie: name,
      });
      if (window._mtm) {
        window._mtm.push({
          event: 'walk',
          mieszkanie: name,
        });
      }
    }
    emitMatomoEvent({
      event: getMatomoEventName(isPromotionFlatCard, 'click_spacer_3d'),
      flat_id: flatID,
    });
  };

  const handleFavorite = e => {
    e.preventDefault();
    handleFavOption(flatData);
  };

  const isFavourite = checkIsFavourite(flatData);

  return (
    <Wrapper>
      <FavBtn
        onClick={e => {
          handleFavorite(e);
          emitMatomoEvent({
            event: isFavourite
              ? getMatomoEventName(
                  isPromotionFlatCard,
                  'click_ulubione_karta_usun'
                )
              : getMatomoEventName(
                  isPromotionFlatCard,
                  'click_ulubione_karta_dodaj'
                ),
            flat_id: flatID,
          });
        }}
        aria-label={!isFavourite ? 'Dodaj do ulubionych' : 'Usuń z ulubionych'}
      >
        <span>
          {!isFavourite ? 'Dodaj do ulubionych' : 'Usuń z ulubionych'}
        </span>
        {isFavourite ? (
          <Icon src={fullHeartIcon} alt="full heart" size={18} />
        ) : (
          <Icon src={heartIcon} alt="heart" size={18} />
        )}
      </FavBtn>
      <InnerWrapper
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
          emitMatomoEvent({
            event: getMatomoEventName(
              isPromotionFlatCard,
              'click_mieszkanie_karta'
            ),
            flat_id: flatID,
          });
          if (typeof window !== 'undefined' && window.innerWidth < 992) {
            toggleForm({ name, investment, flatID });
            if (window.dataLayer && window._mtm) {
              window.dataLayer.push({
                event: 'stworzenie_formularza',
                mieszkanie: name,
              });
              window._mtm.push({
                event: 'stworzenie_formularza',
                mieszkanie: name,
              });
            }

            return;
          }
          if (
            e.target.parentNode.tagName !== 'BUTTON' &&
            e.target.tagName !== 'BUTTON' &&
            e.target.tagName !== 'path' &&
            e.target.tagName !== 'svg'
          ) {
            document
              .querySelector('#browser')
              .scrollIntoView({ behavior: 'smooth' });

            if (isPromotionDrawerVisible) {
              closePromotionDrawer(true);
            }
          }
          setTimeout(() => {
            savedBrowserHistory.push(`/flat/${name}`);
          }, 900);
        }}
      >
        <Main>
          <CardHeading>
            <Heading
              dangerouslySetInnerHTML={{
                __html: `Mieszkanie ${name}`,
              }}
            />
          </CardHeading>
          <ImageWrapper withXXXLStyles={isPromotionFlatCard}>
            <LoadedImage
              src={viewType === 'flat' ? PNG : PNGFloor}
              alt={`${name} plan`}
              full
            />
            {PNG && PNGFloor && (
              <ViewsWrapper>
                <PlanButton
                  onClick={e => {
                    e.stopPropagation();
                    setViewType('flat');
                  }}
                  active={viewType === 'flat'}
                >
                  <span>Widok mieszkania</span>
                  <svg
                    id="house-plan"
                    xmlns="http://www.w3.org/2000/svg"
                    width="20.361"
                    height="20.361"
                    viewBox="0 0 20.361 20.361"
                  >
                    <path
                      id="Path_197"
                      data-name="Path 197"
                      d="M19.765,0H.6A.6.6,0,0,0,0,.6V13.362a.6.6,0,0,0,.6.6H3.571l1.1,1.1a.6.6,0,1,0,.844-.844L4.24,12.94A.606.606,0,0,0,4.02,12.8a15.576,15.576,0,0,0-2.827-.036V8.757l.254.254a.6.6,0,1,0,.844-.844l-1.1-1.1V1.193H7.357V6.721H4.136a.6.6,0,1,0,0,1.193h6.045a.6.6,0,0,0,0-1.193H8.55V1.193H19.168V6.721c-6.263,0-5.866-.016-6.009.036a.605.605,0,0,0-.219.139L11.668,8.168a.6.6,0,0,0,.844.844l1.1-1.1h5.559v4.852H13.362a.6.6,0,0,0,0,1.193h5.806v5.21h-5.21V16.544a.6.6,0,0,0-.6-.6H10.777V13.609l1.1-1.1a.6.6,0,0,0-.844-.844l-1.1,1.1H7a.6.6,0,0,0,0,1.193H9.584v2.585a.6.6,0,0,0,.6.6h2.585v2.625a.6.6,0,0,0,.6.6h6.4a.6.6,0,0,0,.6-.6V.6A.6.6,0,0,0,19.765,0Z"
                    />
                  </svg>
                </PlanButton>
                <PlanButton
                  onClick={e => {
                    e.stopPropagation();
                    setViewType('3D');
                  }}
                  active={viewType === '3D'}
                >
                  <span>Widok piętra</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="13"
                    height="20.377"
                    viewBox="0 0 13 20.377"
                  >
                    <g id="residential-block" transform="translate(-8.357)">
                      <path
                        id="Path_196"
                        data-name="Path 196"
                        d="M21.357.963A.947.947,0,0,0,20.4,0H9.291a.932.932,0,0,0-.934.963V20.377H12.6V17.169a.55.55,0,0,1,.542-.573h3.43a.553.553,0,0,1,.546.573v3.207h4.24V.963ZM14.073,13.774a.333.333,0,0,1-.333.333H11.732a.333.333,0,0,1-.333-.333V11.766a.334.334,0,0,1,.333-.334H13.74a.333.333,0,0,1,.333.334Zm0-4.426a.333.333,0,0,1-.333.333H11.732a.333.333,0,0,1-.333-.333V7.34a.333.333,0,0,1,.333-.333H13.74a.333.333,0,0,1,.333.333Zm0-4.518a.333.333,0,0,1-.333.333H11.732A.333.333,0,0,1,11.4,4.83V2.822a.333.333,0,0,1,.333-.333H13.74a.333.333,0,0,1,.333.333Zm4.242,8.944a.334.334,0,0,1-.334.333H15.973a.333.333,0,0,1-.333-.333V11.766a.333.333,0,0,1,.333-.334h2.008a.334.334,0,0,1,.334.334Zm0-4.426a.334.334,0,0,1-.334.333H15.973a.333.333,0,0,1-.333-.333V7.34a.333.333,0,0,1,.333-.333h2.008a.334.334,0,0,1,.334.333Zm0-4.518a.334.334,0,0,1-.334.333H15.973a.333.333,0,0,1-.333-.333V2.822a.333.333,0,0,1,.333-.333h2.008a.334.334,0,0,1,.334.333Z"
                      />
                    </g>
                  </svg>
                </PlanButton>
                {tour3D && (
                  <PlanButton
                    onClick={e => {
                      e.stopPropagation();
                      handleTourButton();
                    }}
                  >
                    <span>Wirtualny spacer 3D</span>
                    <div>3D</div>
                  </PlanButton>
                )}
              </ViewsWrapper>
            )}
          </ImageWrapper>
        </Main>
        <CardContent>
          <Column>
            <Row>
              <Label>
                <span>Liczba pokoi: </span>
                <Highlight>{rooms}</Highlight>
              </Label>
              {status === 1 && possibleToBook && (
                <ButtonWrapper>
                  <Button
                    onClick={e => {
                      e.stopPropagation();
                      toggleForm(flatData, true);
                      emitMatomoEvent({
                        event: eventsNames.formularz_rezerwacja_wyswietlenie,
                        flat_id: flatID,
                      });
                    }}
                    noHover
                  >
                    Zarezerwuj
                  </Button>
                </ButtonWrapper>
              )}
            </Row>
            <StyledLabel>
              <span>Piętro: </span>
              <FloorsWrapper>
                {includedFloors
                  .sort((a, b) => a.floor - b.floor)
                  .map((el, index) => (
                    <FloorBtn
                      disabled={checkIsFloorDisabled(el.floor)}
                      onClick={e => {
                        e.stopPropagation();
                        handleFloorClick(el.name);
                        emitMatomoEvent({
                          event: getMatomoEventName(
                            isPromotionFlatCard,
                            'click_pietro_zmiana'
                          ),
                          pietro: el.floor,
                          flat_id: flatID,
                        });
                      }}
                      key={`${el.floor}-${index}`}
                      active={name === el.name}
                    >
                      {el.floor}
                    </FloorBtn>
                  ))}
              </FloorsWrapper>
            </StyledLabel>
            <Label>
              <span>Powierzchnia: </span>
              <Highlight>
                {area} M<sup>2</sup>
              </Highlight>
            </Label>
            {priceView !== 'no-price' && (
              <>
                {priceView === 'regular-price' && Number(price) !== 0 && (
                  <Label>
                    <span>Cena: </span>
                    <PriceLabel marginLeft highlighted>
                      {`${price
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')} zł`}
                    </PriceLabel>
                  </Label>
                )}
                {priceView === 'promo-price' && Number(pricePromotion) !== 0 && (
                  <Label>
                    <span>Cena: </span>
                    <PriceLabel marginLeft highlighted>
                      {`${pricePromotion
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')} zł`}
                    </PriceLabel>
                  </Label>
                )}
                {priceView === 'regular-and-promo-price' &&
                  Number(price) !== 0 &&
                  Number(pricePromotion) !== 0 && (
                    <Label>
                      <span>Cena: </span>
                      <PriceWrapper>
                        <PriceLabel crossedOut>
                          {`${price
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')} zł`}
                        </PriceLabel>
                        <PriceLabel highlighted>
                          {`${pricePromotion
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')} zł`}
                        </PriceLabel>
                      </PriceWrapper>
                    </Label>
                  )}
              </>
            )}
            {isPromotion && <PromoLabel>Promocja</PromoLabel>}
          </Column>
          <CardFooter>
            <CardFooterText>Sprawdź</CardFooterText>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18.178"
              height="12"
              viewBox="0 0 18.178 12"
            >
              <path
                d="M13.181,6.006a.718.718,0,0,0,.012.937l.076.074,4.761,3.995H3.217a.718.718,0,0,0-.711.62l-.007.1a.718.718,0,0,0,.718.718H18.058l-4.81,4.036a.718.718,0,0,0,.922,1.1l6.23-5.227a.863.863,0,0,0,.1-1.134l-.077-.075-6.23-5.227A.718.718,0,0,0,13.181,6.006Z"
                transform="translate(-2.5 -5.75)"
              />
            </svg>
          </CardFooter>
          <AskButton>
            <Button
              outlinePrimaryDark
              onClick={() => {
                toggleForm({ name, investment, flatID });
                if (window.dataLayer && window._mtm) {
                  window.dataLayer.push({
                    event: 'stworzenie_formularza',
                    mieszkanie: name,
                  });
                  window._mtm.push({
                    event: 'stworzenie_formularza',
                    mieszkanie: name,
                  });
                }
              }}
            >
              Zapytaj o mieszkanie
            </Button>
          </AskButton>
          <PdfButtonLink
            href={PDF}
            target="_blank"
            rel="noreferrer"
            onClick={e => {
              e.stopPropagation();
            }}
          >
            <Button outlinePrimaryDark>
              Pobierz PDF
              <Icon src={pdfIcon} alt="pdf" size={18} />
            </Button>
          </PdfButtonLink>
        </CardContent>
      </InnerWrapper>
    </Wrapper>
  );
};

FlatCard.propTypes = {
  similarFlats: PropTypes.arrayOf(PropTypes.object),
  flatData: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ).isRequired,
  showTour3D: PropTypes.func.isRequired,
  toggleForm: PropTypes.func.isRequired,
  isPromotionFlatCard: PropTypes.bool,
};

FlatCard.defaultProps = {
  similarFlats: null,
  isPromotionFlatCard: false,
};

export default FlatCard;
