import React, { useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Icon from 'browser/components/Icon';
import arrowRightIcon from 'browser/assets/icons/arrow-right-black.svg';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const PageBtn = styled.button`
  width: 45px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  cursor: pointer;
  font-size: 1.6rem;
  background: ${({ theme, active }) => (active ? '#ffd4e6' : 'transparent')};
  color: ${({ theme, active }) => (active ? '#e60060' : '#272727')};
  @media (hover: hover) {
    &:hover {
      background: ${({ theme, active }) => (active ? '#ffd4e6' : '#F7F7F7')};
    }
  }
`;

const NextPageBtn = styled(PageBtn)`
  background: #f3f0ee;
  &:hover {
    background: #f3f0ee;
  }
`;

const PrevPageBtn = styled(NextPageBtn)`
  transform: rotate(180deg);
`;

const Spacer = styled(PageBtn)`
  position: relative;
  cursor: default;
  &:after {
    content: '...';
    position: absolute;
    font-size: 2.6rem;
    top: -2px;
    left: 50%;
    transform: translateX(-50%);
  }
`;

const Pagination = ({
  activePage,
  setActivePage,
  totalPages,
  selector,
  promotions,
}) => {
  const changePage = page => {
    setActivePage(page);

    const flatsContent = document.querySelector(selector);
    if (flatsContent && !promotions) flatsContent.scrollIntoView();
  };

  useEffect(() => {
    if (totalPages < activePage) {
      changePage(0);
    }
  }, [totalPages]);

  let sliceMin = activePage > 3 ? activePage - 1 : 0;
  const sliceMax = activePage > 3 ? activePage + 2 : 4;

  sliceMin = activePage + 3 >= totalPages ? totalPages - 4 : sliceMin;

  return (
    <Wrapper className="Pagination">
      {activePage !== 0 && (
        <PrevPageBtn
          onClick={() => changePage(activePage - 1)}
          aria-label="poprzednia strona"
        >
          <Icon size={15} src={arrowRightIcon} alt="arrow left" />
        </PrevPageBtn>
      )}
      <>
        {totalPages > 8 ? (
          <>
            {activePage > 3 && <Spacer />}
            {Array(totalPages)
              .fill()
              .map((_, i) => i)
              .slice(sliceMin, sliceMax)
              .map(page => (
                <PageBtn
                  onClick={() => changePage(page)}
                  active={activePage === page}
                  key={page}
                >
                  {page + 1}
                </PageBtn>
              ))}
            {activePage + 3 < totalPages && <Spacer />}
          </>
        ) : (
          Array(totalPages)
            .fill()
            .map((_, i) => i)
            .map(page => (
              <PageBtn
                onClick={() => changePage(page)}
                active={activePage === page}
                key={page}
              >
                {page + 1}
              </PageBtn>
            ))
        )}
      </>
      {activePage + 1 !== totalPages && (
        <NextPageBtn
          onClick={() => changePage(activePage + 1)}
          aria-label="następna strona"
        >
          <Icon size={15} src={arrowRightIcon} alt="arrow right" />
        </NextPageBtn>
      )}
    </Wrapper>
  );
};

Pagination.propTypes = {
  activePage: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  setActivePage: PropTypes.func.isRequired,
  selector: PropTypes.string,
  promotions: PropTypes.bool,
};

Pagination.defaultProps = {
  selector: '#browser',
  promotions: false,
};

export default Pagination;
